const getMood = rating => {
  const status = getSelectedStatus(rating);

  return mood.map(item => ({
    ...item,
    selected: status === item.status,
    unselected: status && status !== item.status
  }));
};

const getSelectedStatus = rating => {
  if (rating >= 38 && rating <= 50) {
    return 'happier';
  }
  if (rating >= 25 && rating <= 37) {
    return 'happy';
  }
  if (rating >= 13 && rating <= 24) {
    return 'sad';
  }
  if (rating >= 0 && rating <= 12) {
    return 'sadder';
  }
  return false;
};

// Returns the rating; Using only state.log.rating causes delay when
// setting mood rating (due to additional API call), setting selected
// as fast as possible improves UX.
const getRating = (activeDate, ratingDate, logRating, moodRating) =>
  (activeDate && !ratingDate) || activeDate === ratingDate
    ? moodRating || logRating
    : logRating;

const mood = [
  {
    id: 0,
    value: 50,
    text: 'Jättebra',
    status: 'happier'
  },
  {
    id: 1,
    value: 33,
    text: 'Bra',
    status: 'happy'
  },
  {
    id: 2,
    value: 22,
    text: 'Jobbig',
    status: 'sad'
  },
  {
    id: 3,
    value: 10,
    text: 'Jättejobbig',
    status: 'sadder'
  }
];

export default {
  getMood,
  getSelectedStatus,
  getRating
};
