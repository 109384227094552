export default {
  kcalPending: false,
  burned: [],
  intake: [],
  recommended: [],
  kcalPendingFourWeeks: false,
  burnedFourWeeks: [],
  intakeFourWeeks: [],
  recommendedFourWeeks: []
};
