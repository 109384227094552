import modals from '@src/utils/constants/modals';
import find from 'lodash/find';

// Returns true if it exist one or more open modals with non scrollable
// background; Filters modals with scrollable background. NB! Not ideal solution
// since this function has to be updated for every modal with scrollable
// background.
const isAtLeastOneModalOpen = ({ activeModals }) => {
  const filteredModals = activeModals.filter(
    item => item.id !== modals.LOG_CONFIRMATION_MODAL
  );
  return filteredModals.length > 0;
};

const isModalOpen = ({ activeModals }, id) =>
  !!find(activeModals, item => item.id === id);

const getModalProps = ({ activeModals }, id) =>
  activeModals.reduce(
    (modalProps, modal) => (modal.id === id ? modal.modalProps : modalProps),
    {}
  );

const isOpening = ({ openingId }, modalId) =>
  openingId && openingId === modalId;

export default {
  isAtLeastOneModalOpen,
  getModalProps,
  isModalOpen,
  isOpening
};
