const NotificationType = {
  bmiTooLow: 'BMI_TOO_LOW',
  clubZeroWeightLimitExceeded: 'CLUB_ZERO_WEIGHT_LIMIT_EXCEEDED',
  milestoneAchieved: 'MILESTONE_ACHIEVED',
  partialGoalAchieved: 'PARTIAL_GOAL_ACHIEVED',
  timeToMeasureWaist: 'TIME_TO_MEASURE_WAIST',
  timeToWeigh: 'TIME_TO_WEIGH',
  weighOverdueNotification: 'WEIGH_OVERDUE_NOTIFICATION',
  weightDecreased: 'WEIGHT_DECREASED',
  weightIncreased: 'WEIGHT_INCREASED',
  weightLossTooFast: 'WEIGHT_LOSS_TOO_FAST',
  weightUnchanged: 'WEIGHT_UNCHANGED',
  weightProgramGoalAchieved: 'WEIGHT_PROGRAM_GOAL_ACHIEVED',
  weightProgramGoalFailed: 'WEIGHT_PROGRAM_GOAL_FAILED',
  weightProgramRestarted: 'WEIGHT_PROGRAM_RESTARTED',
  weightProgramStarted: 'WEIGHT_PROGRAM_STARTED'
};

const PartialGoalNotificationType = {
  weightLossInKilos: 'weight_loss_in_kilos',
  weightLossInPercent: 'weight_loss_in_percent'
};

const eventObjectName = {
  partialGoal: 'Partial goal',
  finalGoal: 'Final goal'
};

const eventObjectCategory = {
  partial: 'partial',
  percentagePartial: 'percentage partial',
  final: 'final'
};

const partialGoalAchievedLens = notifications =>
  notifications.filter(notification => {
    const { notificationType } = notification;
    return notificationType === NotificationType.partialGoalAchieved;
  });

const analyticsEventsLens = notifications =>
  partialGoalAchievedLens(notifications).map(goalNotification => {
    const { metaData = {} } = goalNotification;
    const { finalGoal: isFinalGoal = false } = metaData;
    const { partialGoalType = PartialGoalNotificationType.weightLossInKilos } =
      metaData;

    const objectName = isFinalGoal
      ? eventObjectName.finalGoal
      : eventObjectName.partialGoal;

    const finalGoalObjectCategory = eventObjectCategory.final;
    const partialGoalObjectCategory =
      partialGoalType === PartialGoalNotificationType.weightLossInKilos
        ? eventObjectCategory.partial
        : eventObjectCategory.percentagePartial;
    const objectCategory = isFinalGoal
      ? finalGoalObjectCategory
      : partialGoalObjectCategory;

    return { objectName, objectCategory };
  });

export default {
  analyticsEventsLens
};
