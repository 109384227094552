import types from './types';
import initialState from './initialState';

const logReducer = (state = initialState, action) => {
  const foodItems = mealType =>
    action.response &&
    action.response[mealType] &&
    action.response[mealType].foodItems;

  switch (action.type) {
    case types.CREATE_LOG_PENDING: {
      return { ...state, logPending: true };
    }
    case types.CREATE_LOG_FULFILLED: {
      return {
        ...state,
        logPending: false,
        breakfastItems: foodItems('breakfast'),
        lunchItems: foodItems('lunch'),
        dinnerItems: foodItems('dinner'),
        snackItems: foodItems('snack'),
        supperItems: foodItems('supper'),
        exerciseItems: action.response && action.response.exerciseItems,
        diet: action.response && action.response.diet,
        activeDate: action.response && action.response.dateString,
        recommendedKcal:
          action.response &&
          action.response.diet &&
          action.response.diet.maxKcal,
        rating: action.response && action.response.rating,
        note: action.response && action.response.note
      };
    }
    case types.CREATE_LOG_REJECTED: {
      return { ...state, logPending: false };
    }
    default:
      return state;
  }
};

export default logReducer;
