import { getUserId } from '@src/utils/helpers/authentication';
import { putNote as putNoteFromApi } from '@src/utils/helpers/wsCalls';
import { errorActions } from '@ducks/error';
import { modalsActions } from '@ducks/modals';
import { logActions } from '@ducks/log';
import modals from '@src/utils/constants/modals';
import types from './types';

const putNotePending = () => ({
  type: types.PUT_NOTE_PENDING
});

const putNoteFulfilled = () => ({
  type: types.PUT_NOTE_FULFILLED
});

const putNoteRejected = () => ({
  type: types.PUT_NOTE_REJECTED
});

function putNote(payload, date) {
  return dispatch => {
    const fulfilled = () => dispatch(putNoteFulfilled());
    const rejected = error => {
      dispatch(putNoteRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(putNotePending());

    return putNoteFromApi(fulfilled, rejected, payload, getUserId(), date);
  };
}

function putNoteAndUpdateDashboard(payload, date, modalProps) {
  return dispatch =>
    dispatch(putNote(payload, date)).then(() => {
      dispatch(modalsActions.hideModal(modals.NOTES_MODAL));
      dispatch(modalsActions.showModal(modals.CONFIRMATION_MODAL, modalProps));
      dispatch(logActions.getLog(date));
    });
}

export default {
  putNoteAndUpdateDashboard
};
