import types from './types';
import initialState from './initialState';

const waistReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.PUT_NOTE_PENDING: {
      return { ...state, putPending: true };
    }
    case types.PUT_NOTE_FULFILLED: {
      return {
        ...state,
        putPending: false
      };
    }
    case types.PUT_NOTE_REJECTED: {
      return { ...state, putPending: false };
    }
    default:
      return state;
  }
};

export default waistReducer;
