import { getUserId } from '@src/utils/helpers/authentication';
import { putMoodRating as putMoodRatingFromApi } from '@src/utils/helpers/wsCalls';
import { errorActions } from '@ducks/error';
import types from './types';

const putPending = () => ({
  type: types.PUT_PENDING
});

const putFulfilled = () => ({
  type: types.PUT_FULFILLED
});

const putRejected = () => ({
  type: types.PUT_REJECTED
});

const setRating = (rating, date) => ({
  type: types.SET_RATING,
  rating,
  date
});

// Set rating with posted data to not having to rely on
// getting rating value from log response.
// This enables setting the button as selected faster.
const putFulfilledThunk = (rating, date) => dispatch => {
  dispatch(setRating(rating, date));
  dispatch(putFulfilled());
};

function putMoodRating(date, rating) {
  return dispatch => {
    const fulfilled = () => dispatch(putFulfilledThunk(rating, date));
    const rejected = error => {
      dispatch(putRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(putPending());

    return putMoodRatingFromApi(fulfilled, rejected, getUserId(), date, rating);
  };
}

export default {
  putMoodRating
};
