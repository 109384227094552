/* eslint-disable no-param-reassign */
import types from './types';
import initialState from './initialState';

function updateMenu(state, isShowingPublicMenu, items, selectedIndex) {
  items.forEach((item, index) => {
    if (index === selectedIndex) {
      item.isExpanded = !item.isExpanded;
    } else {
      item.isExpanded = false;
    }
  });

  const updatedState = { ...state, isShowingPublicMenu };

  updatedState.currentMenu = {
    items
  };

  return updatedState;
}

const navbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_MAIN_MENU: {
      return { ...state, menuExpanded: !state.menuExpanded };
    }
    case types.COLLAPS_MAIN_MENU: {
      return { ...state, menuExpanded: false };
    }
    case types.EXPAND_ITEM: {
      const menuItems = state.currentMenu.items.map(item => ({ ...item }));
      return updateMenu(
        state,
        state.isShowingPublicMenu,
        menuItems,
        action.index
      );
    }
    case types.GET_USER_MENU_FULFILLED: {
      const menuItems = state.privateMenu.map(item => ({
        ...item,
        show: true
      }));
      const userItem = {
        ...state.userItem,
        label:
          action.user.displayName ||
          `${action.user.givenName} ${action.user.familyName}`,
        show: true
      };
      menuItems.push(userItem);
      return updateMenu(state, false, menuItems);
    }
    case types.GET_USER_MENU_REJECTED: {
      const menuItems = state.publicMenu.map(item => ({
        ...item,
        show: true
      }));
      const loginItem = {
        ...state.loginItem,
        show: true
      };
      menuItems.push(loginItem);
      return updateMenu(state, true, menuItems);
    }
    case types.SET_REDIRECT_URL: {
      return {
        ...state,
        redirectUrl: action.redirectUrl
      };
    }
    default:
      return state;
  }
};

export default navbarReducer;
