import types from './types';
import initialState from './initialState';

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_NOTIFICATIONS_FULFILLED: {
      return [...state, ...action.notifications];
    }
    case types.CLEAR_NOTIFICATIONS: {
      return [...initialState];
    }
    default:
      return state;
  }
};

export default notificationsReducer;
