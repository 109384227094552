import { getUserId } from '@src/utils/helpers/authentication';
import { errorActions } from '@ducks/error';
import types from './types';

const logPending = () => ({
  type: types.CREATE_LOG_PENDING
});

const logFulfilled = response => ({
  type: types.CREATE_LOG_FULFILLED,
  response
});

const logRejected = () => ({
  type: types.CREATE_LOG_REJECTED
});

function getLog(date) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => dispatch(logFulfilled(response));
    const rejected = error => {
      dispatch(logRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(logPending());

    return wsCalls().getLogFromDate(fulfilled, rejected, getUserId(), date);
  };
}

export default {
  getLog
};
