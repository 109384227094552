import types from './types';

const toggleMenu = () => ({
  type: types.TOGGLE_MAIN_MENU
});

const collapsMenu = () => ({
  type: types.COLLAPS_MAIN_MENU
});

const expandItem = index => ({
  type: types.EXPAND_ITEM,
  index
});

const setRedirectUrl = redirectUrl => ({
  type: types.SET_REDIRECT_URL,
  redirectUrl
});

export const userMenuFulfilled = response => ({
  type: types.GET_USER_MENU_FULFILLED,
  user: {
    displayName: response.displayName,
    familyName: response.familyName,
    givenName: response.givenName
  }
});

export const userMenuRejected = error => ({
  type: types.GET_USER_MENU_REJECTED,
  error: {
    message: error.message
  }
});

export default {
  toggleMenu,
  collapsMenu,
  expandItem,
  setRedirectUrl
};
