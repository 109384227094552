import { errorActions } from '@ducks/error';
import types from './types';

const inspirationFulfilled = response => ({
  type: types.GET_FULFILLED,
  response
});

const inspirationRejected = () => ({
  type: types.GET_REJECTED
});

function getInspirationArticles(isWellobe) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => dispatch(inspirationFulfilled(response));
    const rejected = error => {
      dispatch(inspirationRejected());
      dispatch(errorActions.errorHandler(error));
    };

    wsCalls().getInspirationArticles(fulfilled, rejected, isWellobe);
  };
}

export default {
  getInspirationArticles
};
