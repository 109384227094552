import types from './types';

const showModal = (id, modalProps) => ({
  type: types.SHOW_MODAL,
  id,
  modalProps
});

const hideModal = (id, doNotDeleteAfter = false) => ({
  type: types.HIDE_MODAL,
  id,
  doNotDeleteAfter
});

const setOpening = id => ({
  type: types.SET_OPENING,
  id
});

export default {
  showModal,
  hideModal,
  setOpening
};
