import types from './types';
import initialState from './initialState';

const kcalReducer = (state = initialState, action) => {
  const response = key => action.response && action.response[key];

  switch (action.type) {
    case types.KCAL_PENDING: {
      return { ...state, kcalPending: true };
    }
    case types.KCAL_FULFILLED: {
      return {
        ...state,
        kcalPending: false,
        burned: response('kcalburned') || [],
        intake: response('kcalintake') || [],
        recommended: response('kcalrecommended') || []
      };
    }
    case types.KCAL_REJECTED: {
      return { ...state, kcalPending: false };
    }
    case types.KCAL_PENDING_FOUR_WEEKS: {
      return { ...state, kcalPendingFourWeeks: true };
    }
    case types.KCAL_FULFILLED_FOUR_WEEKS: {
      return {
        ...state,
        kcalPendingFourWeeks: false,
        burnedFourWeeks: response('kcalburned') || [],
        intakeFourWeeks: response('kcalintake') || [],
        recommendedFourWeeks: response('kcalrecommended') || []
      };
    }
    case types.KCAL_REJECTED_FOUR_WEEKS: {
      return { ...state, kcalPendingFourWeeks: false };
    }
    default:
      return state;
  }
};

export default kcalReducer;
