import tracking from '@src/utils/helpers/tracking/tracking';
import types from './types';
import notificationsSelectors from './selectors';

const clearNotifications = () => ({
  type: types.CLEAR_NOTIFICATIONS
});

function trackGoalsAchieved(notifications) {
  return dispatch => {
    const trackingObjects =
      notificationsSelectors.analyticsEventsLens(notifications);
    trackingObjects.forEach(tracking.trackGoalsAchieved);
    if (notifications.length > 0) dispatch(clearNotifications());
  };
}

export default {
  trackGoalsAchieved
};
