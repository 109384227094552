/* eslint-disable no-param-reassign */
import config from '../../config';

const svgs = require.context(`../../components/Navbar/logos/`, true, /\.svg$/);
const logo = svgs(`./logo-${config.productId}.svg`)?.default;

const initialState = {
  logo,
  privateMenu: config.menu.privateMenu,
  publicMenu: config.menu.publicMenu,
  isShowingPublicMenu: true,
  currentMenu: {
    items: []
  },
  userItem: config.menu.userItem,
  loginItem: config.menu.loginItem,
  menuExpanded: false,
  redirectUrl: null
};

export default initialState;
