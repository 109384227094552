export default {
  logPending: false,
  breakfastItems: [],
  lunchItems: [],
  dinnerItems: [],
  snackItems: [],
  supperItems: [],
  exerciseItems: [],
  diet: {},
  activeDate: undefined,
  recommendedKcal: undefined,
  rating: undefined,
  note: undefined
};
