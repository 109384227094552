import { isItWellobe as isWellobe } from '@src/utils/helpers/isWellobe';
import config from '../../config';
import wellobe from './wellobe';
import vektklubb from './vektklubb';

const data = isWellobe(config.product) ? wellobe : vektklubb;

export default {
  dashboardLinks: data.dashboardLinks,
  sections: data.sections,
  tip: data.tip,
  menuBanner: data.menuBanner,
  appsBanner: data.appsBanner,
  recipesRoot: data.recipesRoot
};
