import types from './types';
import initialState from './initialState';

const modalsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_MODAL: {
      return {
        ...state,
        activeModals: [
          ...state.activeModals,
          { id: action.id, modalProps: action.modalProps }
        ],
        openingId: action.id
      };
    }
    case types.HIDE_MODAL: {
      return {
        ...state,
        activeModals: state.activeModals.filter(item => item.id !== action.id)
      };
    }
    case types.SET_OPENING: {
      return {
        ...state,
        openingId: action.id
      };
    }
    default: {
      return state;
    }
  }
};

export default modalsReducer;
