import types from './types';
import initialState from './initialState';

const waistReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.PUT_PENDING: {
      return { ...state, putPending: true };
    }
    case types.PUT_FULFILLED: {
      return {
        ...state,
        putPending: false
      };
    }
    case types.PUT_REJECTED: {
      return { ...state, putPending: false };
    }
    case types.SET_RATING: {
      return { ...state, rating: action.rating, ratingDate: action.date };
    }
    default:
      return state;
  }
};

export default waistReducer;
