import { isItWellobe as isWellobe } from '@src/utils/helpers/isWellobe';
import config from '../../config';
import wellobe from './cdn-assets/wellobe.json';
import vektklubb from './cdn-assets/vektklubb.json';

const data = isWellobe(config.product) ? wellobe : vektklubb;

export default {
  greeting: data.greeting,
  description: data.description,
  bullets: data.bullets,
  notYou: data.notYou,
  changeAccount: data.changeAccount
};
