import { getUserId } from '@src/utils/helpers/authentication';
import { errorActions } from '@ducks/error';
import moment from 'moment';
import { getStartOfWeek, getEndOfWeek } from '@src/utils/helpers/date';
import types from './types';

const kcalPending = () => ({
  type: types.KCAL_PENDING
});

const kcalFulfilled = response => ({
  type: types.KCAL_FULFILLED,
  response
});

const kcalRejected = () => ({
  type: types.KCAL_REJECTED
});

function getKcalnet(fromDate, toDate) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => dispatch(kcalFulfilled(response));
    const rejected = error => {
      dispatch(kcalRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(kcalPending());

    return wsCalls().getKcal(
      fulfilled,
      rejected,
      getUserId(),
      fromDate,
      toDate
    );
  };
}

const kcalPendingFourWeeks = () => ({
  type: types.KCAL_PENDING_FOUR_WEEKS
});

const kcalFulfilledFourWeeks = response => ({
  type: types.KCAL_FULFILLED_FOUR_WEEKS,
  response
});

const kcalRejectedFourWeeks = () => ({
  type: types.KCAL_REJECTED_FOUR_WEEKS
});

function getKcalnetFourWeeks(selectedDate) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => dispatch(kcalFulfilledFourWeeks(response));

    const rejected = error => {
      dispatch(kcalRejectedFourWeeks());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(kcalPendingFourWeeks());

    const date = selectedDate || moment().format('YYYYMMDD');

    return wsCalls().getKcal(
      fulfilled,
      rejected,
      getUserId(),
      getStartOfWeek(date, 21),
      getEndOfWeek(date)
    );
  };
}

export default {
  getKcalnet,
  getKcalnetFourWeeks
};
