import {
  getStarted,
  howItWorks,
  howToSucceed,
  matAnnette,
  matspanerenEliAnne,
  treningSilje,
  elisabeth
} from './images/vektklubb';

export default {
  dashboardLinks: [
    {
      url: '/medlem/vektprogram-og-maal',
      label: 'Se mitt vektprogram'
    }
  ],
  recipesRoot: '/medlem/oppskrifter',
  sections: [
    {
      type: 'recipeSection',
      content: {
        title: 'Oppskrifter',
        link: {
          href: '/medlem/oppskrifter',
          label: 'Alle oppskrifter',
          shortLabel: 'Flere'
        }
      }
    },
    {
      type: 'inspirationSection',
      content: {
        title: 'Artikler',
        link: {
          href: '/inspirasjon',
          label: 'Flere artikler',
          shortLabel: 'Flere'
        }
      }
    },
    {
      type: 'expertSection',
      content: {
        title: 'Våre eksperter',
        link: {
          href: '/eksperter',
          label: 'Alle ekspertartikler',
          shortLabel: 'Flere'
        },
        experts: [
          {
            label: 'Klinisk ernæringsfysiolog',
            image: matAnnette,
            name: 'Anette Skarpaas Ramm',
            title: 'Skriver om mat og kosthold',
            links: [
              {
                href: '/inspirasjon/mat-og-kosthold/kalorier/0nz0mg/saa-enkelt-kan-du-spare-hundrevis-av-kalorier',
                label: 'Så enkelt kan du spare hundrevis av kalorier'
              },
              {
                href: '/eksperter/anette-skarpaas-ramm/qL8rPw/fem-grunner-til-aa-spise-mer-groennsaker',
                label: 'Fem grunner til å spise mer grønnsaker'
              },
              {
                href: '/eksperter/anette-skarpaas-ramm/jdn1Qb/det-foerste-du-boer-gjoere-naar-du-vil-ned-i-vekt',
                label: 'Start livsstilsendrigen i riktig ende'
              }
            ]
          },
          {
            label: 'Kostholds- og treningsekspert',
            image: treningSilje,
            name: 'Silje Bjørnstad',
            title: 'Skriver om kosthold og trening',
            links: [
              {
                href: '/inspirasjon/trening/treningsprogram/opymKg/tren-hele-kroppen-hjemme-uten-aa-dra-paa-treningssenter',
                label: 'Effektiv hjemmetrening på 20 minutter'
              },
              {
                href: '/inspirasjon/mat-og-kosthold/mat-og-maaltider/jdn1Xz/ti-sunne-mellommaaltider-under-200-kalorier',
                label: 'Mellommåltider til under 200 kcal'
              },
              {
                href: '/eksperter/silje-bjoernstad/wPJpK4/4-styrkeoevelser-som-trener-hele-kroppen',
                label: 'Fire styrkeøvelser som trener hele kroppen'
              }
            ]
          },
          {
            label: 'Matspaneren',
            image: matspanerenEliAnne,
            name: 'Eli Anne Myrvoll Blomkvist',
            title: 'Tester nye matvarer hver måned',
            links: [
              {
                href: 'https://www.instagram.com/matspaneren',
                label: 'Følg matspaneren på Instagram',
                reload: true
              },
              {
                href: 'https://support.vektklubb.no/s/contactsupport',
                label: 'Tips om matvarer du vil ha testet',
                reload: true
              }
            ]
          }
        ]
      }
    },
    {
      type: 'aboutSection',
      content: {
        title: 'Om oss',
        items: [
          {
            id: '1',
            title: 'Kom i gang med Vektklubb',
            subtitle:
              'Vi gir deg de beste rådene for hvordan du enkelt kan starte livsstilsendringen!',
            image: {
              src: getStarted,
              alt: 'Food'
            },
            link: '/inspirasjon/nyheter/8mLalE/kom-i-gang-med-vektklubb'
          },
          {
            id: '2',
            title: 'Øk sjansen for å nå målet ditt',
            subtitle:
              'Gjør en liten tankejobb og finn ut hvorfor livsstilsendring er viktig for deg.',
            image: {
              src: howToSucceed,
              alt: 'Mountain and ocean'
            },
            link: '/inspirasjon/motivasjon/motivasjonstips/LAQBMp/slik-naar-du-maalet-ditt'
          },
          {
            id: '3',
            title: 'Slik fungerer vår mobilapp',
            subtitle:
              'Ta med deg Vektklubb overalt! Her viser vi deg hvordan du kan bruke vår smarte mobilapp.',
            image: {
              src: howItWorks,
              alt: 'Cellphone'
            },
            link: '/inspirasjon/nyheter/0nzaj0/slik-fungerer-vaar-mobilapp'
          }
        ]
      }
    },
    {
      type: 'helpSection',
      content: {
        title: '',
        items: [
          {
            id: '1',
            title: 'Hjelp',
            subtitle: '',
            icon: 'zendesk',
            link: '/support',
            external: true
          },
          {
            id: '2',
            title: 'Følg oss på Facebook!',
            subtitle: '',
            icon: 'facebook',
            link: 'https://www.facebook.com/vektklubb/',
            external: true
          },
          {
            id: '3',
            title: 'Få sunn inspirasjon på Instagram',
            subtitle: '',
            icon: 'instagram',
            link: 'https://www.instagram.com/vgvektklubb/',
            external: true
          }
        ]
      }
    }
  ],
  tip: {
    title: 'Tips meg!',
    subtitle: 'Hva vil DU lese mer om? Bli med å påvirke vårt innhold!',
    author: '– Elisabeth Stigum, ansvarlig journalist',
    link: 'mailto:elisabeth.stigum@vg.no',
    image: {
      src: elisabeth,
      alt: 'Elisabeth Stigum - Ansvarlig journalist'
    }
  },
  menuBanner: {
    title: ' ',
    subtitle: 'Ta vår 30-dagers utfordring!',
    content: {
      items: [
        'Spis sunn, enkel og skikkelig digg ma!',
        'Beveg deg mer, uten å slite deg ut',
        'Få daglige tips av våre eksperter'
      ]
    },
    button: 'Til utfordringen',
    url: '/inspirasjon/motivasjon/30dagersutfordring'
  },
  appsBanner: {
    title: 'Last ned mobilappen vår',
    subtitle: 'Logg mat og trening uansett hvor du er',
    info: 'Skann strekkoder og se næringsinnholdet',
    product: 'vektklubb'
  }
};
