import {
  aboutIcons,
  aboutSuccess,
  aboutTheApp,
  expertJosefine,
  expertLeila,
  expertMarten,
  filip
} from './images/wellobe';

export default {
  dashboardLinks: [
    {
      url: '/medlem/program',
      label: 'Se mitt program'
    }
  ],
  recipesRoot: '/medlem/recept',
  sections: [
    {
      type: 'expertSection',
      content: {
        title: 'Våra experter',
        link: {
          href: '/inspiration/traning/QoGR6V/kom-i-form-med-oss',
          label: 'Alla träningsprogram',
          shortLabel: 'Alla'
        },
        experts: [
          {
            label: 'Mat',
            image: expertJosefine,
            href: '/inspiration/nyheter/1nKk4B/josefine-jonasson-dietist-pa-wellobe',
            name: 'Josefine Jonasson',
            title: 'Legitimerad Dietist',
            links: [
              {
                href: '/experter/josefine-jonasson/lEK8JL/stall-fragor-till-mig',
                label: 'Fråga mig'
              },
              {
                href: '/inspiration/kost/8mnlXx/dietistens-kaloriguide',
                label: 'Kaloriguide'
              },
              {
                href: '/experter/josefine-jonasson/LA2pmp/dietistens-portionsguide',
                label: 'Portionsguide'
              },
              {
                href: '/community/blogs/blog/8-dietist-josefine',
                label: 'Blogg',
                reload: true
              }
            ]
          },
          {
            label: 'Motion',
            image: expertMarten,
            href: '/inspiration/nyheter/0n5joo/marten-nylen-traningsexpert-pa-wellobe',
            name: 'Mårten Nylén',
            title: 'Träningsexpert',
            links: [
              {
                href: '/experter/marten-nylen/vAn4wm/smart-traning-med-marten-nylen',
                label: 'Träna med mig'
              },
              {
                href: '/experter/marten-nylen/GGple9/min-grundfilosofi-balansbordet',
                label: 'Min grundfilosofi'
              },
              {
                href: '/inspiration/motivation/GGp1QB/forelasningar-med-marten',
                label: 'Föreläsningar'
              },
              {
                href: '/inspiration/90-dagar/6jLwVW/90-dagar-med-marten-nylen',
                label: '90 dagar med mig'
              }
            ]
          },
          {
            label: 'Motivation',
            image: expertLeila,
            href: '/inspiration/nyheter/RRkb4x/leila-soderholm-motivationsexpert-pa-wellobe',
            name: 'Leila Söderholm',
            title: 'Motivationsexpert',
            links: [
              {
                href: '/experter/leila-soderholm/b5EMVe/trana-med-gummiband',
                label: 'Träna med mig'
              },
              {
                href: '/inspiration/motivation/dOy5aq/lista-dina-3-starkaste-varfor',
                label: 'Lista dina 3 varför'
              },
              {
                href: '/inspiration/motivation/dOy5bO/sluta-mobba-dig-sjalv',
                label: 'Sluta mobba dig själv'
              },
              {
                href: '/community/blogs/blog/9-motivationsexpert-leila',
                label: 'Blogg',
                reload: true
              }
            ]
          }
        ]
      }
    },
    {
      type: 'recipeSection',
      content: {
        title: 'Kaloriberäknade recept',
        link: {
          href: '/medlem/recept',
          label: 'Alla recept',
          shortLabel: 'Alla'
        }
      }
    },
    {
      type: 'inspirationSection',
      content: {
        title: 'Inspiration',
        link: {
          href: '/inspiration',
          label: 'Mer inspiration',
          shortLabel: 'Alla'
        }
      }
    },
    {
      type: 'aboutSection',
      content: {
        title: 'Om oss',
        items: [
          {
            id: '1',
            title: 'Kom igång med Wellobe',
            subtitle:
              'Några hållfasta tips på hur du startar och vad du behöver för att göra resan lättare',
            image: {
              src: aboutIcons,
              alt: 'Kom igång'
            },
            link: '/inspiration/motivation/wPa6xL/kom-igang-med-wellobe'
          },
          {
            id: '2',
            title: 'Så fungerar vår mobilapp',
            subtitle:
              'Här får du instruktioner och smarta tips om våra funktioner i appen',
            image: {
              src: aboutTheApp,
              alt: 'Om appen'
            },
            link: '/inspiration/motivation/Op4bll/sa-fungerar-wellobes-mobilapp'
          },
          {
            id: '3',
            title: 'Öka chansen att nå dina mål',
            subtitle: 'Ny på Wellobe? Därför är den här metoden så effektiv',
            image: {
              src: aboutSuccess,
              alt: 'Frågor'
            },
            link: '/inspiration/motivation/kJla86/oka-chansen-att-na-dina-mal'
          }
        ]
      }
    },
    {
      type: 'helpSection',
      content: {
        title: 'Hjälp och senaste nytt',
        items: [
          {
            id: '1',
            title: 'FAQ',
            subtitle: 'Gå till vår tekniska support',
            icon: 'zendesk',
            link: '/support',
            external: true
          },
          {
            id: '2',
            title: 'Följ oss på Facebook',
            subtitle: 'Få mer tips och råd i någon av våra grupper',
            icon: 'facebook',
            link: 'https://www.facebook.com/wellobe',
            external: true
          },
          {
            id: '3',
            title: 'Följ oss på Instagram',
            subtitle: 'Peppas och få ny inspiration - varje dag',
            icon: 'instagram',
            link: 'https://www.instagram.com/wellobe',
            external: true
          }
        ]
      }
    }
  ],
  tip: {
    title: 'Tipsa mig!',
    subtitle: 'Vad vill DU läsa mer om? Var med och påverka vårt innehåll',
    author: '– Filip Häggberg, Content Manager',
    link: '/inspiration/nyheter/kJ4p8Q/vad-vill-du-lasa-mer-om',
    image: {
      src: filip,
      alt: 'Tips'
    }
  },
  appsBanner: {
    title: 'Ladda ner vår mobilapp',
    subtitle: 'Logga mat och motion vart du än är',
    info: 'Scanna streckoder och se näringsinnehållet',
    product: 'wellobe'
  }
};
